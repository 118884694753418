import React, { useState } from 'react'

import './recuperarContraseña.css'

import logoSN from '../../BASE/img/5-logo.png' 
// import compraExitosa from '../../BASE/img/elementos/slashContraceñaEnviado.png' 
import correoIncorrecto from '../../BASE/img/elementos/xCorreoNoesCorreecto.png' 
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

const RecuperarContraseña = () => {  
  const navigate = useNavigate();

  // const [isSendEmail, setIsSendEmail] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  // const [tokenCorreo, setTokenCorreo] = useState('');
  const [valueEmail, setValueEmail] = useState('')

  const clickRecuContraseña = async(e) =>{
    e.preventDefault();
    let url = 'https://api.mundosantanatura.com/api/StoreEvents/TokenResendPassword'
    try {
      const respuesta = await axios({
        method: 'post',
        url: url,
        data: {
          mail: valueEmail
        },
      })
      if (respuesta.data.result === 1) {
        // setUsuario('')
        // setContraseña('')
        // setCorreo('')
        // setDNI('')
        // let page = (localStorage.getItem("page") === "/ListadoProductos") ? "/" : localStorage.getItem("page");
        // navigate(`${page}`)
        Swal.fire(
          {
            icon: 'success',
            title: 'Excelente!',
            text: 'Se envió un enlace al correo electrónico ingresado para reestablecer su contraseña.',
          }          
        ).then(() => {
            navigate('/Login')
        })
        // setTokenCorreo(respuesta.data.data)
        // setTimeout(() => {
        //   navigate('/NuevaContra');
        // }, 3000);
        const baseUrl = 'http://qr.mundosantanatura.com/NuevaContrasena?';
        const query = 'token=' + respuesta.data.data;
        const encodedQuery = encodeURIComponent(query);
        const urlForApi = baseUrl + encodedQuery;
        console.log(urlForApi);
        let url = 'https://api.mundosantanatura.com/api/StoreEvents/ResendPassword'
        try {
          const respuesta = await axios({
            method: 'post',
            url: url,
            data: {
              url: urlForApi,
              mail: valueEmail
            },
          })
          if (respuesta.data.result === 1) {
            console.log('todo bien');
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        Swal.fire(
          {
            icon: 'error',
            title: 'Oops...!',
            text: `${respuesta.data.message}`
          }          
        )
        // document.querySelector("#mensajeError").textContent = respuesta.data.message;
        // document.getElementById('spanError').textContent = respuesta.message;
      }
    } catch (error) {
      console.log(error)
    }
    // console.log('entré en clickRecuContraseña');
    // setIsSendEmail(!isSendEmail);
    // const regex =
    //   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // if (regex.test(valueEmail) === false) {
    //   console.log("El email no es valido");
    //   setIsEmailInvalid(true);
    //   setIsSendEmail(false);
    // } else {
    //   console.log("El email es correcto");
    //   setIsEmailInvalid(false);
    //   setIsSendEmail(true);
    // }

  }

  // const validateEmail = (e) => {
  //   var email = e.target.value
  
  //   if (validator.isEmail(email)) {
  //     // setEmailError('Valid Email :)')
  //   } else {
  //     setEmailError(!emailError);
  //     // setEmailError('Enter valid Email!')
  //   }
  // }


  return (
    <div className='bloqueRecuperarContra'>
      <div className='bloqueSalir'>
        <Link to='/Login'>Salir</Link>
      </div>
      <img src={logoSN} alt='logoSN' />
      <div className='formRecuperarContraseña'>
        <form onSubmit={clickRecuContraseña}>
          <input type='email' autoComplete='off' required placeholder='Correo electrónico' onChange={(e) => {setValueEmail(e.target.value); setIsEmailInvalid(false)}}></input>
          <input type="submit" value="RECUPERAR CONTRASEÑA" />
        </form>
        {/* <div className='mensajeEnvioSatisfactorio' style={isSendEmail?{display:'flex'}:null}>
          <p>SE ENVIÓ LA CONTRASEÑA<br/>AL CORREO ELECTRÓNICO ASOCIADO<br/> A LA CUENTA</p>
          <img src={compraExitosa} alt='compraExitosa' />
        </div> */}

        <div className='mensajeCorreoIncorrecto' style={isEmailInvalid?{display:'flex'}:null}>
          <p id='mensajeError'></p>
          <img src={correoIncorrecto} alt='correoIncorrecto' />
        </div>
      </div>
      <br />
    </div>
  )
}

export default RecuperarContraseña