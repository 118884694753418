import React, { useEffect, useReducer, useState, startTransition } from 'react'

import { Route, Routes, useLocation } from 'react-router-dom'

import axios from 'axios'

import { TYPES } from '../../STATE/actions/shoppingAction'
import { TYPESPRODUCTS } from '../../STATE/actions/productsAction'
import {
  shoppingInitialState,
  shoppingReducer,
} from '../../STATE/reducers/shoppingReducer'
import {
  productosInitialState,
  productsReducer,
} from '../../STATE/reducers/productsReducer'

// import Header from '../../COMPONENTS/Header/Header'
import ListadoProductos from '../../COMPONENTS/ListadoProductos/ListadoProductos'
import DetalleProducto from '../DetalleProducto/DetalleProducto'
import TuCarrito from '../../COMPONENTS/TuCarrito/TuCarrito'
import Footer from '../../COMPONENTS/Footer/Footer'
import CompraExitosa from '../../COMPONENTS/CompraExitosa/CompraExitosa'
import Login from '../Login/Login'
import RecuperarContraseña from '../../COMPONENTS/RecuperarContra/RecuperarContraseña'
import NuevaContraseña from '../../COMPONENTS/RecuperarContra/NuevaContraseña'
import Registrarse from '../../COMPONENTS/Registrarse/Registrarse'

import '../../BASE/styleGeneral.css'
import './master.css'
import PantallaDeCarga from '../../COMPONENTS/PantallaDeCarga/PantallaDeCarga'

// import { arrayProductosPrueba } from './ArrayProductosPrueba'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

const Header = React.lazy(() => import('../../COMPONENTS/Header/Header'))

const Tienda = () => {
  const [isVisibleCharginScreen, setIsVisibleCharginScreen] = useState(true);
  const [direcFactu, setDirecFactu] = useState('');
  const [comprobante, setComprobante] = useState({});

  //   const [dataArregloPrueba, setDataArregloPrueba] = useState('');

  const location = useLocation()

  const [state, dispatch] = useReducer(shoppingReducer, shoppingInitialState)
  const { cart } = state

  const [stateProducts, productsDispatch] = useReducer(productsReducer, productosInitialState)
  const { stateListProducts } = stateProducts

  useEffect(() => {
    setTimeout(() => {
      startTransition(() => {
        setIsVisibleCharginScreen(false)
      });
    }, 4500);
    const initFetch = async () => {
      const req = await axios.post('https://api.mundosantanatura.com/api/StoreEvents/GetProductsStore')
      // console.log(req.data.data)
      const arregloPrueba = req.data.data;
      //   const unSoloProducto = [
      //     {
      //         "idProductoPais": "PP00813",
      //         "nombre": "Yo Vital Hombre",
      //         "imagen": "YoVitalHombre.png",
      //         "precio": 15.0,
      //         "categoria": "Batidos Súper Proteínas"
      //     }
      //   ]
      // setDataArregloPrueba(arregloPrueba);
      productsDispatch({ type: TYPESPRODUCTS.FETCH_PRODUCTS, payload: arregloPrueba })
    }
    initFetch()
  }, [])
  // console.log(stateListProducts)

  // const function1 = () => {
  //   let aValue = localStorage.getItem('bgcolor')

  //   const dataLS = JSON.parse(aValue)

  //   const cart = dataLS.cart
  //   return cart
  // }

  const addToCart = (id, price, img, desc, peso) => {
    // console.log('entré en addToCart')
    dispatch({
      type: TYPES.ADD_TO_CART,
      payload: [id, 1, price, img, desc, peso, true],
    })
  }

  const addOneMoreFromProductAtCart = (id) => {
    // console.log('entré en addOneMoreFromProductAtCart')
    dispatch({
      type: TYPES.ADD_ONE_MORE_FROM_PRODUCT_AT_CART,
      payload: [id, true],
    })
  }

  const removeOneMinusFromProductAtCart = (id) => {
    // console.log('entré en addOneMoreFromProductAtCart')
    dispatch({
      type: TYPES.REMOVE_ONE_MINUS_FROM_PRODUCT_AT_CART,
      payload: [id, true],
    })
  }

  const delFromCart = (id, all = false) => {
    if (all) {
      // console.log('entré en delFromCart all=true')
      dispatch({ type: TYPES.REMOVE_ALL_FROM_CART, payload: id })
    } else {
      // console.log('entré en delFromCart all=false')
      dispatch({ type: TYPES.REMOVE_ONE_FROM_CART, payload: id })
    }
  }

  const clearCart = () => {
    dispatch({ type: TYPES.CLEAR_CART })
  }

  const cerrarModal = () => {
    document.getElementById('modal').style.opacity = '0';
    document.querySelector('body').style.overflowY = '';
    setTimeout(() => {
      document.getElementById('modal').style.display = 'none';
    }, "100")
  }

  const showDetailProduct = (productoBuscado) => {
    productsDispatch({ type: TYPESPRODUCTS.FETCH_PRODUCTS, payload: productoBuscado })
  }

  const updateSubtotalCartProductFromDetailProduct = (id, cant, precio, img, nom, peso) => {
    // console.log('entré en addOneMoreFromProductAtCart')
    dispatch({
      type: TYPES.UPDATE_SUBTOTALCARTPRODUCT_DETAILPRODUCT,
      payload: [id, cant, precio, img, nom, peso, true],
    })
  }

  const registrarDireccionFiscal = (e) => {
    e.preventDefault();
    if(direcFactu !== ''){
      var combo = document.getElementById('idSelectTipoComprobante');
      var selected = combo.options[combo.selectedIndex].text;
      const direccionFiscal = {
        direccionFiscal: direcFactu
      };
      const tipoComprobante = {
        tipoComprobante: selected
      };
      const numeroRuc = {
        numeroRuc: document.getElementById('idInputIngreseRUC').value
      };
      const finalResult = Object.assign(direccionFiscal,tipoComprobante,numeroRuc);
      // setComprobante({...comprobante, valueDirecFactu: direcFactu})
      // setComprobante({...comprobante, tipoComprobante: document.getElementById('idSelectTipoComprobante').value})
      // setComprobante({...comprobante, numeroRuc: document.getElementById('idInputIngreseRUC').value})  
      setComprobante(finalResult);
      document.getElementById('txtDireccionFiscalRuc').value = "";
      cerrarModal();
      Swal.fire({
        title: 'RUC Válido!',
        text: `¡Perfecto! Todos los datos han sido validados correctamente.`,
        icon: 'success',
        confirmButtonText: 'OK'
      })
    }
  }

  const limpiarObjetoComprobante = (param) =>{
    setComprobante(param);
  }

  return (
    isVisibleCharginScreen
      ?
      <PantallaDeCarga />
      :
      <div className="bloqueTienda">
        <div id="modal">
          <div className='bloqueIngresarDireccionFiscal'>
            <input id="cerrar-modal" name="modal" type="radio" />
            <label htmlFor="cerrar-modal" onClick={cerrarModal}>X</label>
            <p className='cabeceraModal'>INGRESAR DIRECCIÓN</p>
            <hr />
            <p>El RUC se validó correctamente. Sin embargo, la dirección no fue proporcionada por SUNAT, porfavor ingrese la dirección fiscal que figura en su ficha RUC. Ejemplo: JR. SAN ISIDRO MZ Z LOTE 09 SUBIDA A YAUYOS LIMA - CAÑETE - CERRO AZUL</p>
            <div>
              <form onSubmit={registrarDireccionFiscal}>
                <p>Dirección Fiscal RUC:</p>
                <input id='txtDireccionFiscalRuc' type="text" required autoComplete='off' onChange={(e)=>setDirecFactu(e.target.value)}/>
                <input type='submit' value='REGISTRAR'/>
              </form>
            </div>
          </div>
        </div>
        {location.pathname !== '/Login' &&
          location.pathname !== '/Registrarse' &&
          location.pathname !== '/NuevaContrasena' &&
          location.pathname !== '/RecuperarContrasena' &&
          location.pathname !== '/CompraExitosa' && <Header cart={cart} data={stateListProducts} showDetailProduct={showDetailProduct} 
          clearCart={clearCart} />}
        <Routes>
          <Route
            path="/"
            exact
            element={
              <ListadoProductos data={stateListProducts} addToCart={addToCart} />
            }
          />
          <Route
            path="/DetalleProducto"
            element={
              <DetalleProducto
                cart={cart}
                data={stateListProducts}
                // addOneMoreFromProductAtCart={addOneMoreFromProductAtCart}
                // removeOneMinusFromProductAtCart={removeOneMinusFromProductAtCart} 
                updateSubtotalCartProductFromDetailProduct={updateSubtotalCartProductFromDetailProduct}
              />
            }
          />
          <Route
            path="/TuCarrito"
            element={
              <TuCarrito
                // function1={function1}
                delFromCart={delFromCart}
                clearCart={clearCart}
                cart={cart}
                addOneMoreFromProductAtCart={addOneMoreFromProductAtCart}
                removeOneMinusFromProductAtCart={removeOneMinusFromProductAtCart}
                // direcFactu={direcFactu}
                objetoComprobante={comprobante}
                limpiarObjetoComprobante={limpiarObjetoComprobante}
              />
            }
          />
          <Route path="/CompraExitosa" element={<CompraExitosa />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/RecuperarContrasena" element={<RecuperarContraseña />} />
          <Route path="/NuevaContrasena" element={<NuevaContraseña />} />
          <Route path="/Registrarse" element={<Registrarse />} />
        </Routes>
        {/* <ListadoProductos /> */}
        {/* <DetalleProducto />
        <UbicacionPedido />
        <TuCarrito /> */}

        {location.pathname !== '/Login' && location.pathname !== '/Registrarse' && location.pathname !== '/FinalizarRegistro' && <Footer />}
      </div>

  )
}

export default Tienda