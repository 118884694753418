import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import FinalizarRegistro from './FinalizarRegistro'

import barraPortada from '../../BASE/img/elementos/barraPortadaME_414x33px.png'
import logoSN from '../../BASE/img/elementos/logoSNNOMe.png'
// import compraExitosa from '../../BASE/img/5-icono-compra-exitosa.png'

import '../../../node_modules/font-awesome/css/font-awesome.min.css'
import './registrarse.css'
import axios from 'axios'

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
// import { createGlobalStyle } from 'styled-components'

const Registrarse = () => {
  // const navigate = useNavigate();
  const [isSendData, setIsSendData] = useState(true);
  // const [showPassword, setShowPassword] = useState(true);

  // const [isLoading, setIsLoading] = useState(false);
  const [isOjitoClassNameActive, setIsOjitoClassNameActive] = useState(true)

  const [errorEmail, setErrorEmail] = useState(false)
  const [errorDNI, setErrorDNI] = useState(false)

  const [usuario, setUsuario] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [correo, setCorreo] = useState('');
  const [dni, setDNI] = useState('');

  // const clickRegistrarse = (e) =>{
  //   e.preventDefault();
  //   console.log('entré en clickRegistrarse');
  //   setIsSendData(!isSendData);
  // }

  //Para cambiar el ojito
  // const changeShowPassword = (e) => {
  //   e.preventDefault();
  //   setShowPassword(!showPassword);
  //   let txtContra :any = document.getElementById('txtContra');
  //   if(showPassword){
  //     txtContra.type = 'text';
  //   }else{
  //     txtContra.type = 'password'
  //   }
  // }

  const RegisterClient = async (e) =>{
    e.preventDefault();
    let url = 'https://api.mundosantanatura.com/api/StoreEvents/RegisterClientQR'
    try {
      const respuesta = await axios({
        method: 'post',
        url: url,
        data: {
          user: usuario,
          password: contraseña,
          mail: correo,
          document: dni
        },
      })
      if (respuesta.data.result === 1) {
        // setUsuario('')
        // setContraseña('')
        // setCorreo('')
        // setDNI('')
        // let page = (localStorage.getItem("page") === "/ListadoProductos") ? "/" : localStorage.getItem("page");
        // navigate(`${page}`)
        setIsSendData(!isSendData);
      } else {
        // console.log(respuesta.data.message);
        Swal.fire({
          title: 'Error!',
          text: `${respuesta.data.message}`,
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((result) => {
          if(result.isConfirmed){
            if(respuesta.data.message === "El documento ingresado ya se encuentra registrado"){
              setErrorDNI(true);
            } else {
              setErrorEmail(true);
            }
          }
        })
        // document.getElementById('spanError').textContent = respuesta.message;
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='bloqueRegistrarse'>
      <img className='imgBarraPortada' src={barraPortada} alt="barraPortada" />
      {
        !!isSendData
        ?
        <div id='bloqueSalir' className='bloqueSalir'>
          <Link to='/Login'>Salir</Link>
        </div>
        : null
      }
      
      <img className='imgLogoSN' src={logoSN} alt='logoSN' />
      {
        !!isSendData
        ?
        <form onSubmit={RegisterClient}>
          <h1>¡REGISTRARSE!</h1>
          <label htmlFor="txtUsu">
            <span>Usuario</span>
            <input id='txtUsu' className='inputText' autoComplete='off' required maxLength={8} type='text' onChange={(e) => {
                setUsuario(e.target.value)
              }} onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText focus'} else{e.target.className = 'inputText'}}}
            />
          </label>
          <label htmlFor="txtContra"  className='txtContra'>
            <span>Contraseña</span>
            <div>
              <input id='txtContra' className='inputText' maxLength={15} required type={`${isOjitoClassNameActive ? "password" : "text"}`} onChange={(e) => {
                  setContraseña(e.target.value)
                }} onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText focus'} else{e.target.className = 'inputText'}}}
              />
              <i className={`${isOjitoClassNameActive ? "fa fa-eye-slash" : "fa fa-eye"}`} onClick={() => setIsOjitoClassNameActive(!isOjitoClassNameActive)}></i>
              {/* <button onClick={(e)=>{changeShowPassword(e)}} className={`ojito ${isOjitoClassNameActive ? "fa fa-eye-slash" : "fa fa-eye"}`}>{!showPassword ? 'Ocultar' : 'Mostrar'}</button> */}
            </div>
          </label>
          <label htmlFor="txtEmail">
            <span className={`${errorEmail ? "activeError" : null}`}>Dirección de correo electrónico</span>
            <input className={`inputText ${errorEmail ? "activeError" : null}`} autoComplete='off' required type='email' id='txtEmail' onChange={(e) => {
                setCorreo(e.target.value)
                setErrorEmail(false)
              }} onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText focus'} else{e.target.className = 'inputText'}}}
              />
          </label>
          <label htmlFor="txtDNI">
            <span className={`${errorDNI ? "activeError" : null}`}>DNI</span>
            <input className={`inputText ${errorDNI ? "activeError" : null}`} id='txtDNI' autoComplete='off' required minLength={8} type="text" onChange={(e) => {
                setDNI(e.target.value)
                setErrorDNI(false)
              }} onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText focus'} else{e.target.className = 'inputText'}}}
              />
          </label>
          {/* <input className='inputText' placeholder='Apellidos'/>
          <input className='inputText' placeholder='Celular'/>
          <input className='inputText' placeholder='DNI'/>
          <input className='inputText' placeholder='Correo'/> */}
          {/* <p className={`spanError ${error ? "active" : null}`}>{msgError}</p> */}
          <p>Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web, gestionar el acceso a tu cuenta y otros propósitos descritos en nuestra <span>política de privacidad</span></p>

          <div className='bloqueButtons'>
            <input type='submit' placeholder='Correo' value='REGISTRARSE'/>  
          </div>
        </form>
        :
        // <div className='mensajeEnvioSatisfactorio'>
        //   <p>¡REGISTRO COMPLETO!</p>
        //   <img src={compraExitosa} alt='compraExitosa'/>
        // </div>
        <FinalizarRegistro nombreUsu={usuario} dni={dni} />
      }
      <br />
    </div>
  )
}

export default Registrarse