import React, { useEffect, useState }  from 'react'
import { Link, useNavigate } from 'react-router-dom'

// import { Link } from 'react-router-dom'

// import imgProducto from '../../BASE/img/elementos/ConcentradoKalmaprossME_320x320px.png'
// import imgCallCenter from '../../BASE/img/4-icono-call-center.png'
import imgWsp from '../../BASE/img/elementos/WhatsappIconoMe_18x18Px.png'

import './detalleProducto.css'

import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from 'axios'

const DetalleProducto = ({data, cart, updateSubtotalCartProductFromDetailProduct}) => {
  const navigate = useNavigate();

  const [productDetail, setProductDetail] = useState({})
  const [nombreImagenForCarrito, setNombreImagenForCarrito] = useState()

  localStorage.setItem('page', window.location.pathname+window.location.search);

  // const productDetail = data.filter(d => d.idProductoPais === idProd);//Hace un filtro de data (array de objetos del estado) para obtener el objeto donde su nombre sea el mismo que el que se está obtiene de la url enviado desde la página de Tienda (componente ProducItem)
  //Obteniendo lo que se ha enviado por la URL desde ProductItem, en este caso el nombre del producto.
  var currentLocation1 = window.location.href;
  var dec1 = decodeURIComponent(currentLocation1);
  var currentPageUrl1 = new URL(dec1);
  var idProd1 = currentPageUrl1.searchParams.get("idProducto");//Obtiene el nombre del producto de la url, donde el nombre el producto se está pasando como parámetro
  const productCart = cart.filter(c => c.idProducto === idProd1)
  // console.log(productCart)
  // setProductCart(cart.filter(c => c.idProducto === idProd1));//Hace un filtro de cart (array de objetos en el LocalStorage) para obtener el objeto donde la descripción del producto sea igual al nombre del producto que se está obtiene de la url enviado desde la página de Tienda (componente ProducItem)

  useEffect(() => {
    if(!localStorage.getItem('loggedUser')){//Si no estoy logueado
      navigate('/Login')
    }

    const getProductDetailQR = async()=>{
      //Obteniendo lo que se ha enviado por la URL desde ProductItem, en este caso el nombre del producto.
      var currentLocation = window.location.href;
      var dec = decodeURIComponent(currentLocation);
      var currentPageUrl = new URL(dec);
      var idProd = currentPageUrl.searchParams.get("idProducto");//Obtiene el nombre del producto de la url, donde el nombre el producto se está pasando como parámetro
      const getProductFromData = data.filter(d => d.idProductoPais === idProd);//Hace un filtro de data (array de objetos del estado) para obtener el objeto donde su nombre sea el mismo que el que se está obtiene de la url enviado desde la página de Tienda (componente ProducItem)
      setNombreImagenForCarrito(getProductFromData[0].imagen);

      let url = 'https://api.mundosantanatura.com/api/StoreEvents/GetProductDetailQR'
      try {
        const respuesta = await axios({
          method: 'post',
          url: url,
          data: {
            idProductCountry: idProd,
            idCliente: '',
            documento: ''
          },
        })
        setProductDetail(respuesta.data.data);
      } catch (error) {
        console.log(error)
      }
    }
    getProductDetailQR();
  }, [data, navigate])


  let cant = 1;//Si no existe en el carrito, que valga 0, caso contrario, que valga la cantidad que hay en productCart

  // const [hola, setHola] = useState(productCart[0].subTotal);

  const minusFunction = () =>{
    const num = document.querySelector(".num");
    if (cant > 1) {
      // console.log('minusFunction')
      cant--;
      // setHola(productDetail[0].precio*a)
      // a = (a < 10) ? "0" + a : a;
      num.innerText = cant;
    }
  }
  const plusFunction = () =>{
    // console.log('plusFunction')
    const num = document.querySelector(".num");
    cant++;
    // setHola(productDetail[0].precio*a)
    // a = (a < 10) ? "0" + a : a;
    num.innerText = cant;
  }
// console.log(productCart)
  var styleElem = document.head.appendChild(document.createElement("style"));
  styleElem.innerHTML = `.bloqueCantProductos .bloqueEnlace button.btnContinuarCompra::after {content: 's/ ${productCart[0] ? productCart[0].subTotal : 0}.00';}`;

  return (
    <div className='bloqueGeneralDetalleProducto'>
      <div className='bloqueDetalleProducto'>
        <LazyLoadImage src={`https://tienda.mundosantanatura.com/productsDetails/${productDetail.imagenDetalle}`} alt={productDetail.nombre} effect='blur'/>
        <div>
          <div className='bloqueNombreProducto'>
            <p>{productDetail.nombre}</p>
          </div>
          <div className='bloqueDescProducto'>
            <p>{productDetail.descripcion}</p>
          </div>
          <div className='bloqueCostoProducto'>
            <p>s/<span> {productDetail.precio}.00</span></p>
            <div className='bloqueDescuento'>
              <div className='bloqueDescuento__porcentanje'>
                <span>-50%</span>
              </div>
              <p className='bloqueDescuento__texto'>Todos los medios de pago</p>
            </div>
          </div>
        </div>
      </div>
      <div className='bloqueViasDeCompra'>
        {/* <a href="tel:+51999999999">
          <div className='bloqueViaCompraCallCenter'>
            <img src={imgCallCenter} alt='imgCallCenter' />
            <div className='bloqueInfoCallCenter'>
              <p>Compra vía Call Center</p>
              <span>000 000 000</span>
            </div>
          </div>
        </a> */}
        <div className='bloquesContactarParaComprar'>
          <a href='https://api.whatsapp.com/send?phone=949018652&text=Hola,%20deseo%20solicitar%20productos%20Santa%20Natura.'>
            <div className='bloqueViaWsp'>
              <img src={imgWsp} alt='imgWsp' />
              <div className='bloqueInfoWsp'>
                <p>Compra vía Whatsapp</p>
                <span>949 018 652</span>
              </div>
            </div>
          </a>          
        </div>
        <div className="wrapper">
          <span className="minus" onClick={()=>minusFunction()}>-</span>
          <span className="num" id='num'>1</span>
          <span className="plus" onClick={()=>plusFunction()}>+</span>
        </div>
        <button className="btnWrapperAgregar" onClick={()=>{updateSubtotalCartProductFromDetailProduct(productDetail.idProductoPais, cant, productDetail.precio, nombreImagenForCarrito, productDetail.nombre, productDetail.peso); document.getElementById('num').textContent="1"}}>AGREGAR</button>
      </div>
      <div className='bloqueCantProductos'>
        <div className='bloqueEnlace'>
          <Link to='/'>
            <button className='btnAgregarProducts'>
              AGREGAR <br />MAS PRODUCTOS
            </button>
          </Link>
          <Link to='/TuCarrito'>
            <button className='btnContinuarCompra'></button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default DetalleProducto