import React, { useState } from 'react'

import axios from 'axios'

import movilUbi from '../../BASE/img/4-icono-delivery.png'
import movilUbiWhite from '../../BASE/img/4-icono-delivery-blanco.png'
import tiendaUbi from '../../BASE/img/4-boton-recojo-tienda.png'
import tiendaUbiWhite from '../../BASE/img/4-boton-recojo-tienda-blanco.png'

import './ubicacionPedido.css'

const UbicacionPedido = ({direccion_Delivery, getCostoDeEnvío}) => {
  const [isSelect1, setIsSelect1] = useState(false)
  const [isSelect2, setIsSelect2] = useState(false)

  const [valueUbicacion, setValueUbicacion] = useState('')

  // const [showInput, setShowInput] = useState(false);

  // const [departamento, setDepartamento] = useState([]);

  // const [ubiIngresada, setUbiIngresada] = useState('')

  function addSelectInInput(input){
    input.innerHTML = "";
    var option = document.createElement("option");
    option.value = "000";
    option.text = "Seleccione:";
    input.appendChild(option);
  }

  let valor = 0;

  const data = {
    "pais": "01",
  };
  axios
    .post("https://api.mundosantanatura.com/api/Acces/GetDepartamento", data)
    .then((response) => {
      valor++;
      if(valor===1){
        const lista = response.data;
        var cboDepartamento = document.getElementById("valueSelectDepartamento");
        // var cboProvincia = document.getElementById("valueSelectProvincia");
        // var cboDistrito = document.getElementById("valueSelectDistrito");
        // addSelectInInput(cboDepartamento);
        // addSelectInInput(cboProvincia);
        // addSelectInInput(cboDistrito);
        lista.forEach((dep) => {
          var option2 = document.createElement("option");
          option2.value = dep.codigo;
          option2.text = dep.nombre;
          cboDepartamento.appendChild(option2);
        });
      }
    })
    .catch((error) => console.error(error));
    // document.getElementById('valueSelectProvincia').value='0128'

  const data2 = {
    "departamento": '015',
  };
  axios
    .post("https://api.mundosantanatura.com/api/Acces/GetProvincia", data2)
    .then((response) => {
      const lista = response.data;
      //console.log(typeof lista);
      var cboProvincia = document.getElementById("valueSelectProvincia");
      // var cboDistrito = document.getElementById("valueSelectDistrito");
      // addSelectInInput(cboProvincia);
      // addSelectInInput(cboDistrito);
      // setTimeout(() => {          
      //   getCostoDeEnvío();
      // }, 10);            
      lista.forEach((dep) => {
        var option2 = document.createElement("option");
        option2.value = dep.codigo;
        option2.text = dep.nombre;
        cboProvincia.appendChild(option2);
      });
    })
    .catch((error) => console.error(error));


  // const showValuesSelectProvincia = () => {    
  //   // var valueSelectDepartamento = document.getElementById("valueSelectDepartamento").value;
  //   const data = {
  //     "departamento": '015',
  //   };
  //   axios
  //     .post("https://api.mundosantanatura.com/api/Acces/GetProvincia", data)
  //     .then((response) => {
  //       const lista = response.data;
  //       //console.log(typeof lista);
  //       var cboProvincia = document.getElementById("valueSelectProvincia");
  //       var cboDistrito = document.getElementById("valueSelectDistrito");
  //       addSelectInInput(cboProvincia);
  //       addSelectInInput(cboDistrito);
  //       setTimeout(() => {          
  //         getCostoDeEnvío();
  //       }, 10);            
  //       lista.forEach((dep) => {
  //         var option2 = document.createElement("option");
  //         option2.value = dep.codigo;
  //         option2.text = dep.nombre;
  //         cboProvincia.appendChild(option2);
  //       });
  //     })
  //     .catch((error) => console.error(error));
  // };

  const showValuesSelectDistrito = () => {    
    // var valueSelectProvincia = document.getElementById("valueSelectProvincia").value;
    const data = {
      "provincia": '0128',
    };
    axios
      .post("https://api.mundosantanatura.com/api/Acces/GetDistrito", data)
      .then((response) => {
        const lista = response.data;        
        setTimeout(() => {          
          getCostoDeEnvío();
        }, 10);  
        //console.log(typeof lista);
        var cboDistrito = document.getElementById("valueSelectDistrito");
        addSelectInInput(cboDistrito);
        lista.forEach((dep) => {
          var option2 = document.createElement("option");
          option2.value = dep.codigo;
          option2.text = dep.nombre;
          cboDistrito.appendChild(option2);
        });
      })
      .catch((error) => console.error(error));      
  };

  const changeActiveBloqueMovilUbi = (e) => {
    document.getElementById('valueSelectDepartamento').value='015';
    // showValuesSelectProvincia();
    document.getElementById('valueSelectProvincia').value='0128';
    showValuesSelectDistrito();

    setValueUbicacion('');
    direccion_Delivery('');
    e.preventDefault();
    if (isSelect1 !== true) {
      setIsSelect1(!isSelect1)
      if (isSelect2 === true) {
        setIsSelect2(!isSelect2)
      }
    }

    
  }

  const changeActiveBloqueTiendaUbi = (e) => {
    document.getElementById("valueSelectDepartamento").value = '015';

    // var cboProvincia = document.getElementById("valueSelectProvincia");
    var cboDistrito = document.getElementById("valueSelectDistrito");
    // addSelectInInput(cboProvincia);
    addSelectInInput(cboDistrito);
    getCostoDeEnvío();
    e.preventDefault();
    direccion_Delivery('');
    direccion_Delivery('Av. Jorge Basadre 990, San Isidro, Lima');
    if (isSelect2 !== true) {
      setIsSelect2(!isSelect2)
      if (isSelect1 === true) {
        setIsSelect1(!isSelect1)
      }
    }
  }

  return (
    <div className="bloqueUbicacionPedido">
      <section className="bloqueIngresarUbicacion">
        <div>
          <h2>Elige un tipo de entrega</h2>
        </div>
        <div
          id='bloqueMovilUbi'
          className={`${
            !!isSelect1 ? 'bloqueMovilUbi selected' : 'bloqueMovilUbi'
          } `}
          onClick={changeActiveBloqueMovilUbi}
        >
          {!!isSelect1 ? (
            <img src={movilUbiWhite} alt="movilUbi" />
          ) : (
            <img src={movilUbi} alt="movilUbi" />
          )}
          <p>Ingresa tu dirección para conocer el costo de envío a domicilio.</p>
        </div>
        <div
         id='bloqueTiendaUbi'
          className={`${
            !!isSelect2 ? 'bloqueTiendaUbi selected' : 'bloqueTiendaUbi'
          } `}
          onClick={(e) => {changeActiveBloqueTiendaUbi(e);setValueUbicacion('Av. Jorge Basadre 990, San Isidro, Lima');}}
        >
          {!!isSelect2 ? (
            <img src={tiendaUbiWhite} alt="tiendaUbi" />
          ) : (
            <img src={tiendaUbi} alt="movilUbi" />
          )}
          <div>
            <span>SAN ISIDRO</span>
            <p>Av. Jorge Basadre 990, San Isidro, Lima</p>
          </div>
        </div>
      </section>

      <section className="bloqueIngresaDireccion" style={!!isSelect1 ? {display: "block"} : {display: "none"}}>
        <div className='bloqueSelectsDepProvDis'>
          <div>
            <p>Departamento</p>
            <select
              id="valueSelectDepartamento"
              // onChange={() => showValuesSelectProvincia()}
              disabled={true}
            ></select>
          </div>
          <div>
            <p>Provincia</p>
            <select
              id="valueSelectProvincia"
              // onChange={() => showValuesSelectDistrito()}
              disabled={true}
            ></select>
          </div>
          <div>
            <p>Distrito</p>
            <select
              id="valueSelectDistrito"
              onChange={() => getCostoDeEnvío()}
            ></select>
          </div>
          
        </div>
        <input
          // onKeyDown={(e)=>keyDownInputUbi(e)}
          // onChange={(e)=>onChangeInputUbicacion(e.target)}
          // onKeyDown={(e)=> onKeyDownInputUbicacion(e)}
          onKeyUp={(e)=> {direccion_Delivery(e.target.value); if(e.target.value!==''){e.target.className = 'inputText focus'} else{e.target.className = 'inputText'}}}
          id="idInputDireccion"
          type="text"
          placeholder="Ingresa tu dirección"
          tabIndex="0"
          autoComplete='off'
        ></input>
      </section>
      {/* ) : null} */}
      {
        isSelect2
        ?
        <div id='idBloqueLocalSeleccionado' className="bloqueLocalSeleccionado">
          <label>Local seleccionado para retiro</label>
          <input id='idInputLocalSanIsidro' value={valueUbicacion} readOnly></input>
        </div>    
        :
        null    
      }
    </div>
  )
}

export default UbicacionPedido
