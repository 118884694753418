import React from 'react'

import tachoBasura from '../../BASE/img/6-tacho-basura.png'

import './cartItem.css'

const CartItem = ({ data, delFromCart, masUno, menosUno}) => {
  let { idProducto, cantidad, precio, subTotal, imagen, descProducto } = data

  // let a = 1;
  // const minusFunction = () =>{
  //   const num = document.querySelector(".num");
  //   if (a > 1) {
  //     a--;
  //     a = (a < 10) ? "0" + a : a;
  //     num.innerText = a;
  //   }
  // }
  // const plusFunction = (id) =>{
  //   // const num = document.querySelector(".num");
  //   // a++;
  //   // a = (a < 10) ? "0" + a : a;
  //   // num.innerText = a;
  //   masUno(id);
  // }



  return (
    // <div style={{ borderBottom: "thin solid gray" }}>
    //   <h4>{name}</h4>
    //   <h5>
    //     ${price}.00 x {quantity} = ${price * quantity}.00
    //   </h5>
    //   <button onClick={() => delFromCart(id)}>Eliminar Uno</button>
    //   <br />
    //   <button onClick={() => delFromCart(id, true)}>Eliminar Todos</button>
    //   <br />
    //   <br />
    // </div>
    <article className="bloqueInfoListaProductos">
      <div className="bloqueImgProducto">
        <img src={`https://tienda.mundosantanatura.com/products/${imagen}`} alt={imagen} />
      </div>
      <div className="bloqueInfoCantidadProducto">
        <div className="bloqueInfoEliminar">
          <div>
            <p>{descProducto}</p>
            <p>s/{precio}.00 x {cantidad} = s/{subTotal}.00</p>
          </div>
          <img src={tachoBasura} alt="tachoBasura" onClick={() => delFromCart(idProducto)} />
        </div>
        <div className="bloqueWrapperCantProductos">
          <div className="wrapper">
            <span className="minus" onClick={()=>menosUno(idProducto)}>
              -
            </span>
            <span className="num">{cantidad}</span>
            <span className="plus" onClick={()=>masUno(idProducto)}>
              +
            </span>
          </div>
        </div>
      </div>
    </article>
  )
}

export default CartItem
