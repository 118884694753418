import React from 'react'

import { useNavigate } from 'react-router-dom'

import logoSN from '../../BASE/img/5-logo.png'
import compraExitosa from '../../BASE/img/5-icono-compra-exitosa.png'

import './compraExitosa.css'

const CompraExitosa = () => {
  const navigate = useNavigate()

  const goInicio = () => {
    navigate('/')
  }

  return (
    <div className="bloqueCompraExitosa">
      <img src={logoSN} alt="logoSN" />
      <section className="sectionContenidoCompraExitosa">
        <div className="mensajeCompraExitosa">
          <p>¡COMPRA EXITOSA!</p>
          <img src={compraExitosa} alt="compraExitosa" />
        </div>
        <div className='bloqueAgradecimiento'>
          <h3>Gracias por su compra</h3>
          <p>
            ¡Gracias por todo su apoyo! <br /> Nos encantaría recibir sus
            comentarios acerca de la compra, dirijase al inicio de nuestra
            página web y díganos su opinión.
          </p>
          <button onClick={() => goInicio()}>VUELVE AL INICIO</button>
        </div>
      </section>
      <br/>
    </div>
  )
}

export default CompraExitosa
