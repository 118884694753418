import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import RegistroFinalizadoSantanura from '../../BASE/img/elementos/RegistroFinalizadoSantanuratin.png'
// import holaIDsantanarutin from '../../BASE/img/elementos/holaIDsantanarutin.png'

// ES6 Modules or TypeScript
// import Swal from 'sweetalert2'

const FinalizarRegistro = ({nombreUsu, dni}) => {
  const navigate = useNavigate();
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [celular, setCelular] = useState('');

  const [isCompletedSuccesfullRegistration, setIsCompletedSuccesfullRegistration] = useState(false);

  const CompleteCustomerRegistration = async (e) =>{
    e.preventDefault();
    let url = 'https://api.mundosantanatura.com/api/StoreEvents/CompleteCustomerRegistrationQR'
    try {
      const respuesta = await axios({
        method: 'post',
        url: url,
        data: {
          document: dni,
          names: nombres,
          lastNames: apellidos,
          cellphone: celular
        },
      })
      if (respuesta.data.result === 1) {
        // setUsuario('')
        // setContraseña('')
        // setCorreo('')
        // setDNI('')
        // let page = (localStorage.getItem("page") === "/ListadoProductos") ? "/" : localStorage.getItem("page");
        setIsCompletedSuccesfullRegistration(true);
        setTimeout(() => {
          navigate('/Login')
        }, 2000);
      } 
      // else {
        // console.log(respuesta.data.message);
        // Swal.fire({
        //   title: 'Error!',
        //   text: `${respuesta.data.message}`,
        //   icon: 'error',
        //   confirmButtonText: 'OK'
        // }).then((result) => {
        //   if(result.isConfirmed){
        //     if(respuesta.data.message === "El documento ingresado ya se encuentra registrado"){
        //       setErrorDNI(true);
        //     } else {
        //       setErrorEmail(true);
        //     }
        //   }
        // })
        // document.getElementById('spanError').textContent = respuesta.message;
      // }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='bloqueFinalizarRegistro'>
    {
      !!isCompletedSuccesfullRegistration
      ?
      <img className='imgRegistroFinalizado' src={RegistroFinalizadoSantanura} alt='RegistroFinalizadoSantanura'/>
      :
      <div>
        <div className='holaIDsantanarutin'>
          <p>{nombreUsu}</p>
        </div>
        <form onSubmit={CompleteCustomerRegistration}>
          <label htmlFor="txtNombre">
            <input id='txtNombre' className='inputText' required autoComplete='off' placeholder='Nombres' 
            onChange={(e) => {setNombres(e.target.value)}} onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText focus'} else{e.target.className = 'inputText'}}}/>
          </label>
          <label htmlFor="txtApellido">
            <input id='txtApellido' className='inputText' required autoComplete='off' placeholder='Apellidos' 
            onChange={(e) => {setApellidos(e.target.value)}} onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText focus'} else{e.target.className = 'inputText'}}}/>
          </label>
          <label htmlFor="txtCelular">
            <input id='txtCelular' className='inputText' required autoComplete='off' placeholder='Celular' 
            onChange={(e) => {setCelular(e.target.value)}} onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText focus'} else{e.target.className = 'inputText'}}}/>
          </label>
          <p>Los datos solicitados son con fines informativos para obtener el comprobante de pago</p>
          <input type="submit" value='FINALIZAR REGISTRO' />
        </form>
      </div>      
    }
    
    </div>
  )
}

export default FinalizarRegistro