import React, { useEffect } from 'react'

import ProductItem from '../ProductItem/ProductItem'

import { Swiper, SwiperSlide } from 'swiper/react'

import { useNavigate } from 'react-router-dom'

import { Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/free-mode'
// import 'bootstrap/dist/css/bootstrap.min.css'
import './listadoProductos.css'

import BotonPortadaDeslisableIzquierda from '../../BASE/img/elementos/BotonPortadaDeslisableIzquierda.png'
import BotonPortadaDeslisableDerecha from '../../BASE/img/elementos/BotonPortadaDeslisableDerecha.png'

const ListadoProductos = ({ data, addToCart }) => {
  const navigate = useNavigate();
  
  // function hola() {
  //   data.forEach((element) => {
  //     return console.log(element)
  //   })
  // }
  // hola()

  let hash = {}
  const nuevosElementos = data.filter((o) =>
    hash[o.categoria] ? false : (hash[o.categoria] = true),
  )
  // console.log(nuevosElementos)

  // nuevosElementos.forEach((n) => {
  //   console.log(data.filter((producto) => producto.categoria === n.categoria))
  // })

  useEffect(() => {
    if(!localStorage.getItem('loggedUser')){//Si no estoy logueado
      navigate('/Login')
    }

    localStorage.setItem('page', '/ListadoProductos');
    if(data.length === 1){
      const padreSwiperWrapper = document.querySelector('.swiper-wrapper');
      console.log(padreSwiperWrapper);
      var elCount = padreSwiperWrapper.childElementCount;
      console.log(elCount);
      if (elCount !== 1) {
        console.log('entré en hijo1')
        padreSwiperWrapper.firstChild.style.display = "none";
        padreSwiperWrapper.lastChild.style.display = "none";
        // document.querySelector('.bloquePadreListadoProductos').style.height = "100%";
      }
      padreSwiperWrapper.firstChild.style.width = "100%";
      padreSwiperWrapper.firstChild.style.marginRight = "0";
      document.querySelector('.sectionListadoProductos .swiper').style.width="100%";
      // document.querySelector('.swiper-wrapper').style.justifyContent = "center";
      // document.querySelector('.bloquePadreListadoProductos').style.height = "calc(100vh - 303px)";
      // const hijoUltimo = document.querySelector('.swiper-slide-active.swiper-slide-duplicate-next');
      // if (hijoUltimo.parentNode) {
      //   console.log('entré en hijoUltimo')
      //   hijoUltimo.parentNode.removeChild(hijoUltimo);
      // }
    }
  }, [data.length, nuevosElementos, navigate]);

  const bloqueListadoProductos = nuevosElementos.map(
    (n, key) => (
      // console.log(n.categoria)
      <section className="bloqueSectionCategoria" key={key}>
        <div className='bloqueNombreCategoria'>
          <p>{n.categoria}</p>
          <hr />
        </div>
        {/* {you} */}
        <div className="sectionListadoProductos">
          <Swiper
            loop={true}
            modules={[Navigation]}
            className="mySwiper"
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            breakpoints={{
              0: {
                slidesPerView: 4,
                spaceBetween: 8.7,
              },
              480: {
                slidesPerView: 4,
                spaceBetween: 8.7,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >

            <div className='bloqueSwiperButtons'>
              <div className="swiper-button-prev"><img src={BotonPortadaDeslisableIzquierda} alt="" /></div>
              <div className="swiper-button-next"><img src={BotonPortadaDeslisableDerecha} alt="" /></div>
            </div>
            {data.filter((producto) => producto.categoria === n.categoria).map((product, index) => (
              <SwiperSlide key={index}>
                <ProductItem data={product} addToCart={addToCart} />
              </SwiperSlide>
            ))}

          </Swiper>
        </div>
      </section>
    ),

    // const productosXcategoria = data.filter((producto) => {
    //   return producto.categoria === n.categoria
    // })

    // console.log(productosXcategoria)
  )

  return (
    <section className="bloquePadreListadoProductos">
      {bloqueListadoProductos}

      {/* {data.map((product, index) =>
        <ProductItem key={index} data={product} addToCart={addToCart} />
      )} */}
      {/* <button>Show Alert</button> */}
      {/* <div id="bloqueAlert" className="alert hide">
        <span className="fas fa-exclamation-circle"></span>
        <span className="msg">PRODUCTO AÑADIDO!</span>
      </div> */}
    </section>
  )
}

export default ListadoProductos
