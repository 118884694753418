import React from 'react'

import CacheBuster from 'react-cache-buster';
import version from '../../package.json';

import { BrowserRouter as Router } from 'react-router-dom'

import Master from '../PAGES/Master/Master'

const RoutesTienda = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={`${version}`}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <Router>
        <Master />
      </Router>
    </CacheBuster>
  )
}

export default RoutesTienda