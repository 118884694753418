import React from 'react'

import imgFooter from '../../BASE/img/elementos/PieDePaginaME_1242x196px.png'

import './footer.css'

const Footer = () => {
  return (
    <footer className='bloqueImgFooter'>
      <p>¡Vivir bien está<br /> en nuestras raíces!</p>
      {/* <img src={imgFooter} alt='imgFooter'/> */}
    </footer>
  )
}

export default Footer