import axios from 'axios'
import { TYPES } from '../actions/shoppingAction'

export const shoppingInitialState = {
  cart: JSON.parse(localStorage.getItem('carrito')) || [],
}

export function shoppingReducer(state, action) {
  switch (action.type) {
    case TYPES.ADD_TO_CART: {
      const repeat = state.cart.some(
        (repeatProduct) => repeatProduct.idProducto === action.payload[0],
      )
      const getAnswerConsultStock = async()=>{
        let url = 'https://api.mundosantanatura.com/api/StoreEvents/GetProductDetailQR'
        try {
          const respuesta = await axios({
            method: 'post',
            url: url,
            data: {
              idProductCountry: action.payload[0],
              quantity: action.payload[1],
              idCliente: '',
              documento: ''
            },
          })
          if(!!respuesta.data.data){
            console.log('Si hay stock suficiente.')
          }else{
            console.log('No hay stock suficiente.')
          }
        } catch (error) {
          console.log(error)
        }
      }
      getAnswerConsultStock();
      if (repeat) {//si el producto existe en el carrito, que se modifique la cantidad y el subtotal
        if(action.payload[6]){
          const product= state.cart.find((item) =>
            item.idProducto === action.payload[0]
          )
          product.cantidad++;
          product.subTotal=product.precio*product.cantidad;
          product.subTotalPesoProducto=product.pesoProducto*product.cantidad;
          action.payload[6]=false;
        }
        localStorage.setItem('carrito', JSON.stringify(state.cart))
      } else {//si no, si el producto no existe en carrito, que lo agregue ahí
        state.cart.push({
          idProducto: action.payload[0],
          cantidad: action.payload[1],
          precio: action.payload[2],
          subTotal: action.payload[2] * action.payload[1],
          imagen: action.payload[3],
          descProducto: action.payload[4],
          pesoProducto: action.payload[5],
          subTotalPesoProducto: action.payload[5] * action.payload[1]
        })
        localStorage.setItem('carrito', JSON.stringify(state.cart))
        action.payload[6]=false;
      }
      return {
        cart: JSON.parse(localStorage.getItem('carrito')),
      }
    }
    case TYPES.ADD_ONE_MORE_FROM_PRODUCT_AT_CART: {
        if(action.payload[1]){
          const product= state.cart.find((item) =>
            item.idProducto === action.payload[0]
          )
          product.cantidad++;
          product.subTotal=product.precio*product.cantidad;
          product.subTotalPesoProducto=product.pesoProducto*product.cantidad;
          action.payload[1]=false;
        }
      localStorage.setItem('carrito', JSON.stringify(state.cart))//actualiza la info en el localStorage seteando(enviando) el state.cart

      return {
        cart: JSON.parse(localStorage.getItem('carrito')),
      }
    }
    case TYPES.REMOVE_ONE_MINUS_FROM_PRODUCT_AT_CART: {
        if(action.payload[1]){
          const product= state.cart.find((item) =>
            item.idProducto === action.payload[0]
          )
          if(product.cantidad > 1){
            product.cantidad--;
            product.subTotal=product.precio*product.cantidad;
            product.subTotalPesoProducto=product.pesoProducto*product.cantidad;
          }
          action.payload[1]=false;
        }
      localStorage.setItem('carrito', JSON.stringify(state.cart))

      return {
        cart: JSON.parse(localStorage.getItem('carrito')),
      }
    }
    case TYPES.REMOVE_ONE_FROM_CART: {
      state.cart = [...state.cart.filter(
        (item) => item.idProducto !== action.payload,
      )]
      localStorage.setItem('carrito', JSON.stringify(state.cart))

      return {
        cart: [...JSON.parse(localStorage.getItem('carrito'))],
      }
    }
    case TYPES.REMOVE_ALL_FROM_CART: {
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload),
      }
    }
    case TYPES.UPDATE_SUBTOTALCARTPRODUCT_DETAILPRODUCT: {      
        if(action.payload[6]){
          const product= state.cart.find((item) =>
            item.idProducto === action.payload[0]
          )
          if(product !== undefined){//si existe el producto en el carrito, que la cantidad y el subtotal se actualicen
            product.cantidad = product.cantidad + action.payload[1];
            product.subTotal=product.precio*product.cantidad;
            product.subTotalPesoProducto=product.pesoProducto*product.cantidad;
            action.payload[6]=false;
          }else{
            state.cart.push({
              idProducto: action.payload[0],
              cantidad: action.payload[1],
              precio: action.payload[2],
              subTotal: action.payload[2] * action.payload[1],
              imagen: action.payload[3],
              descProducto: action.payload[4],
              pesoProducto: action.payload[5],
              subTotalPesoProducto: action.payload[5] * action.payload[1]
            })
    
            localStorage.setItem('carrito', JSON.stringify(state.cart))
            action.payload[6]=false;
          }
          
        }
      localStorage.setItem('carrito', JSON.stringify(state.cart))//actualiza la info en el localStorage seteando(enviando) el state.cart

      // state.cart = [...state.cart, ...cantidad=cantidadAñadida]
      return {
        cart: JSON.parse(localStorage.getItem('carrito')),
      }
    }
    case TYPES.CLEAR_CART:
      state.cart = [];
      localStorage.setItem('carrito', JSON.stringify(state.cart));
      return {
      cart: JSON.parse(localStorage.getItem('carrito'))
    }
    default:
      return state
  }
}
