import { TYPESPRODUCTS } from '../actions/productsAction'

export const productosInitialState = {
  stateListProducts: []
}

export function productsReducer(stateProducts, actionProducts) {
  switch(actionProducts.type) {
    case TYPESPRODUCTS.FETCH_PRODUCTS:{
        // console.log(actionProducts.payload);
        stateProducts.stateListProducts = actionProducts.payload
        // console.log(stateProducts.stateListProducts);
        return {...stateProducts, ...stateProducts.stateListProducts}
    }
    default:
        return stateProducts
}
}
