import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { getToken, setToken } from '../Master/auth-helpers.js'

import '../../../node_modules/font-awesome/css/font-awesome.min.css'

import './login.css'

const Login = () => {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState('')
  const [contraseña, setContraseña] = useState('')
  // const [authenticated, setauthenticated] = useState('');
  // const [token, setToken] = useState('');
  const [error, setError] = useState(false)
  const [isOjitoClassNameActive, setIsOjitoClassNameActive] = useState(true)

  // var URLactual = window.location;
  // console.log(URLactual);
  useEffect(() => {
    if (getToken()) {
      // Si me he logueado y tengo token, que me redireccione a la página Queries
      navigate('/TuCarrito')
    }
  }, [navigate])

  const validarLogin = async (e) => {
    e.preventDefault()
    let url = 'https://api.mundosantanatura.com/api/Acces/LoginQR'
    try {
      const respuesta = await axios({
        method: 'post',
        url: url,
        data: {
          usuario: usuario,
          password: contraseña,
        },
      })
      console.log(respuesta)
      if (respuesta.data.result === 1) {

        setUsuario('')
        setContraseña('')
        // window.localStorage.setItem("loggedQueriesAssistantUser", respuesta.data.data.accesToken);
        setToken(JSON.stringify(respuesta.data.data))

        let page = (localStorage.getItem("page") === "/ListadoProductos") ? "/" : localStorage.getItem("page");

        navigate(`${page}`);
        
      } else {
        setError(true)
        setUsuario('')
        setContraseña('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="bloqueLogin">
      <form onSubmit={validarLogin}>
        <div className="bloqueInput">
          <label htmlFor="usuario" className="input-label">
            Usuario:
          </label>
          <input
            value={usuario}
            type="text"
            id="usuario"
            required
            maxLength={8}
            autoComplete="off"
            className="input"
            name="usuario"
            onChange={(e) => {
              setUsuario(e.target.value);
              setError(false);
            }}
          />
        </div>
        <div className="bloqueInput">
          <label htmlFor="contraseña" className="input-label">
            Contraseña:
          </label>
          <input
            value={contraseña}
            type={`${isOjitoClassNameActive ? "password" : "text"}`}
            id="contraseña"
            required
            maxLength={15}
            className={`input ${isOjitoClassNameActive ? "active" : ""}`}
            name="contraseña"
            onChange={(e) => {
              setContraseña(e.target.value);
              setError(false);
            }}
          />
          <div className="divIconEye">
            <i
              id="icon"
              className={`${
                isOjitoClassNameActive ? "fa fa-eye-slash" : "fa fa-eye"
              }`}
              onClick={() => setIsOjitoClassNameActive(!isOjitoClassNameActive)}
            ></i>
          </div>
        </div>
        <div className="input-spanError">
          <span className={`spanError ${error ? "active" : null}`}>
            Usuario o contraseña incorrecta. Por favor, vuelve a intentarlo
            nuevamente.
          </span>
        </div>
        <div className="bloqueRecuperarContraseña">
          <Link to="/RecuperarContrasena">
            <p>¿Has olvidado tu contraseña?</p>
          </Link>
        </div>
        <div className="bloqueButtonLogin">
          <input className="inputLogin" type="submit" value="INICIAR SESIÓN" />
          <Link to="/Registrarse">REGÍSTRATE</Link>
        </div>
        <div className="bloqueIndicacionIndispensable">
          <p>
            *Es indispensable registrarse correctamente para la boleta de pago.
          </p>
        </div>
      </form>
    </div>
  );
}

export default Login