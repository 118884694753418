import React from 'react'

import { useNavigate } from 'react-router-dom'

import { LazyLoadImage } from "react-lazy-load-image-component";

import 'react-lazy-load-image-component/src/effects/blur.css';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

const ProductItem = ({ data, addToCart, estado }) => {
    let { idProductoPais, nombre, precio, imagen, peso } = data;

    const navigate = useNavigate()

    const goDetailsProduct = (e) => {
      e.preventDefault();
      // const baseUrl = 'http://localhost:3000/DetalleProducto?';
      const query = 'idProducto=' + idProductoPais;
      const encodedQuery = encodeURIComponent(query);
      // const url = baseUrl + encodedQuery;
      // window.location.href = url;
      navigate('/DetalleProducto?'+encodedQuery)
      // console.log(window.location.href)
    }
    const openAlert = () =>{
      Swal.fire({
        position: 'top-end',
        width: 200,
        icon: 'success',
        title: 'SE AÑADIÓ EL PRODUCTO!',
        showConfirmButton: false,
        timer: 1500
      })
      // const bloqueAlert = document.getElementById('bloqueAlert');
      // bloqueAlert.style.removeProperty("opacity");
      // bloqueAlert.classList.add("show");
      // bloqueAlert.classList.remove("hide");
      // bloqueAlert.classList.add("showAlert");
      // setTimeout(function(){
      //   bloqueAlert.classList.remove("show");
      //   bloqueAlert.classList.add("hide");
      // },50);
      // setTimeout(function(){
      //   bloqueAlert.style.opacity = "0";
      //   bloqueAlert.classList.remove("showAlert");
      // },2000);
    }

    return (
        // <div style={{ border: "thin solid gray", padding: "1rem" }}>
        //     <h4>{desc}</h4>
        //     <h5>${price}.00</h5>
        //     <button onClick={() => addToCart(id)}>Agregar</button>
        // </div>
        <article>
            <button className='goDetailsProduct' onClick={(e) => goDetailsProduct(e)}>
              <div className='bloqueImgProduct'>
                  <LazyLoadImage src={`https://tienda.mundosantanatura.com/products/${imagen}`} alt={imagen} effect='blur'/>
                  {/* <img src={`https://prueba3.mundosantanatura.com/imagenesPrueba/productos/${imagen}`} alt={imagen} /> */}
              </div>
              <p className='nameProduct'>{nombre}</p>
            </button>
            <p className='priceProduct'>S/<span>{precio}</span></p>
            <div className='bloqueBtnAñadirCarrito'>
              <button className='añadirCarrito' onClick={() => {addToCart(idProductoPais, precio, imagen, nombre, peso); openAlert()}}>AÑADIR</button>
            </div>
      </article>
    )
}

export default ProductItem