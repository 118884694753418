import React from 'react'

import './pantallaDeCarga.css'
import logoCentro from '../../BASE/img/elementos/logo291x92px.png'

const PantallaDeCarga = () => {
  return (
    <div className="fondo">
      <hr />
      <img className="logoCentro" src={logoCentro} alt="1-logo" />
      <hr />
    </div>
  )
}

export default PantallaDeCarga
