import React, { useState } from 'react'

import './recuperarContraseña.css'
import '../../../node_modules/font-awesome/css/font-awesome.min.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'

const NuevaContraseña = () => {
  const navigate = useNavigate();

  const [contraseña, setContraseña] = useState('')
  const [contraseña2, setContraseña2] = useState('')
  // const [error, setError] = useState(false)
  const [isOjitoClassNameActive, setIsOjitoClassNameActive] = useState(true)
  const [isOjitoClassNameActive2, setIsOjitoClassNameActive2] = useState(true)

  const changeNewPassword = async(e) =>{
    e.preventDefault();
    var currentLocation = window.location.href;
    var dec = decodeURIComponent(currentLocation);
    var urlActualPage = new URL(dec);
    var token = urlActualPage.searchParams.get("token");
    console.log(token)
    console.log(contraseña)
    if(contraseña === contraseña2){
      let url = 'https://api.mundosantanatura.com/api/StoreEvents/RecreatePassword'
      try {
        const respuesta = await axios({
          method: 'post',
          url: url,
          data: {
            token: token,
            psw: contraseña
          },
        })
        if (respuesta.data.result === 1) {
          Swal.fire(
            {
              icon: 'success',
              title: 'Excelente!',
              text: 'Se actualizó correctamente su nueva contraseña.',
            }
          ).then(() => {
            navigate('/Login')
          })
          // const baseUrl = 'http://localhost:3000/NuevaContrasena?';
          // const query = 'token=' + respuesta.data.data;
          // const encodedQuery = encodeURIComponent(query);
          // const urlForApi = baseUrl + encodedQuery;
          // console.log(urlForApi);
        } else {
          document.querySelector("#mensajeError").textContent = respuesta.data.message;
        }
      } catch (error) {
        console.log(error)
      }
    }else{
      Swal.fire(
        {
          icon: 'error',
          title: 'Oops...!',
          text: 'Las contraseñas no coinciden.',
        }
      )
    }
  }
  return (
    <div className="bloqueNuevaContraseña">
      <form onSubmit={changeNewPassword}>
        <h1>Nueva Contraseña</h1>
        <label htmlFor="txtContra">
          <span>Introduzca nueva contraseña</span>
          <div>
            <input
              id="txtContra"
              className="inputText password1"
              maxLength={15}
              required
              type={`${isOjitoClassNameActive ? "password" : "text"}`}
              onChange={(e) => {
                setContraseña(e.target.value);
              }}
              onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText password1 focus'} else{e.target.className = 'inputText password1'}}}
            />
            <i
              className={`${
                isOjitoClassNameActive ? "fa fa-eye-slash" : "fa fa-eye"
              }`}
              onClick={() => setIsOjitoClassNameActive(!isOjitoClassNameActive)}
            ></i>
            {/* <button onClick={(e)=>{changeShowPassword(e)}} className={`ojito ${isOjitoClassNameActive ? "fa fa-eye-slash" : "fa fa-eye"}`}>{!showPassword ? 'Ocultar' : 'Mostrar'}</button> */}
          </div>
        </label>
        <label htmlFor="txtContra2">
          <span>Confirmar nueva contraseña</span>
          <div>
            <input
              id="txtContra2"
              className="inputText password2"
              maxLength={15}
              required
              type={`${isOjitoClassNameActive2 ? "password" : "text"}`}
              onChange={(e) => {
                setContraseña2(e.target.value);
              }}
              onKeyUp={(e)=> {if(e.target.value!==''){e.target.className = 'inputText password2 focus'} else{e.target.className = 'inputText password2'}}}
            />
            <i
              className={`${
                isOjitoClassNameActive2 ? "fa fa-eye-slash" : "fa fa-eye"
              }`}
              onClick={() => setIsOjitoClassNameActive2(!isOjitoClassNameActive2)}
            ></i>
            {/* <button onClick={(e)=>{changeShowPassword(e)}} className={`ojito ${isOjitoClassNameActive ? "fa fa-eye-slash" : "fa fa-eye"}`}>{!showPassword ? 'Ocultar' : 'Mostrar'}</button> */}
          </div>
        </label>
        <div className='bloqueBtnCambiar'>
          <input type="submit" value="CAMBIAR" />
        </div>
      </form>
    </div>
  );
}

export default NuevaContraseña