import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import btnAtrasCarrito from '../../BASE/img/6-boton-atras-carrito.png'

import './tuCarrito.css'
import CartItem from '../CartItem/CartItem'
import UbicacionPedido from '../UbicacionPedido/UbicacionPedido'
import axios from 'axios'

// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

const variableGlobal={
  idProductoCountry:"PP00892",
  nombre:"DELIVERY",
  cantidad: 1,
  subTotal: 0,
  price: 0
}

const TuCarrito = ({ delFromCart, clearCart, cart, addOneMoreFromProductAtCart, removeOneMinusFromProductAtCart, objetoComprobante, limpiarObjetoComprobante }) => {
  const navigate = useNavigate();

  const [direccion, setDireccion] = useState('');

  const [isFacturaSelected, setIsFacturaSelected] = useState(false)
  const [tipoComprobante, setTipoComprobante] = useState('');

  const [costoEnvío, setCostoEnvío] = useState(0);

  const [first, setfirst] = useState('')

  // let addressPersonal = ''
  useEffect(() => {
    if(!localStorage.getItem('loggedUser')){//Si no estoy logueado
      navigate('/Login')
    }

    async function getAddress () {
      // const token = "feLO0xIWlBK97vMqsdWdCBVBVwbtFAv8Q7Nn7TOzD1i2lyi3pO8QTf0jGwOm";
      let url = 'https://api.mundosantanatura.com/api/StoreEvents/ValidarDNI'
      try {
        const respuesta = await axios({
          method: 'post',
          url: url,
          withCredentials: false,
          headers: {
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' },
          data: {
            document: JSON.parse(localStorage.getItem('loggedUser')).document,
            // force: true
          },
        })
        setfirst(respuesta.data.data.address)
      } catch (error) {
        console.log(error)
      }
    };

    if(localStorage.getItem('loggedUser')){//Si no estoy logueado
      getAddress();
    }
  }, [navigate])

  function direccion_Delivery(event) {
    setDireccion(event);
  }

  // function validarRUC(event) {
  //   setComprobante(event);
  // }
  // function tipo_Comprobante(event) {
  //   setDireccion(event);
  // }

  localStorage.setItem('page', window.location.pathname);

  async function getCostoDeEnvío () {
    // console.log('entré en getCostoDeEnvío')
    // console.log(document.getElementById("valueSelectDepartamento")[document.getElementById("valueSelectDepartamento").selectedIndex].text)
    var cboDepartamento = document.getElementById("valueSelectDepartamento")[document.getElementById("valueSelectDepartamento").selectedIndex].text;
    var cboProvincia = document.getElementById("valueSelectProvincia")[document.getElementById("valueSelectProvincia").selectedIndex].text;
    var cboDistrito = document.getElementById("valueSelectDistrito")[document.getElementById("valueSelectDistrito").selectedIndex].text;
    // console.log(cboDepartamento);
    // console.log(cboProvincia);
    // console.log(cboDistrito);
    const pesoTotalPedido = cart.reduce((pesoTotalPedido, produc) => pesoTotalPedido += produc.subTotalPesoProducto, 0);
    // console.log(pesoTotalPedido)
    // if(cboDepartamento !== 'Seleccione:' && cboProvincia !== 'Seleccione:' && cboDistrito !== 'Seleccione:'){
    if(cboDistrito !== 'Seleccione:'){
      let url = 'https://api.mundosantanatura.com/api/StoreEvents/GetPriceDelivery'
      try {
        const respuesta = await axios({
          method: 'post',
          url: url,
          data: {
            departamento: cboDepartamento,
            provincia: cboProvincia,
            distrito: cboDistrito,
            peso: pesoTotalPedido
          },
        })
        // console.log(respuesta.data.data.price)
        // console.log(respuesta.data.data)
        setCostoEnvío(respuesta.data.data);
      } catch (error) {
        console.log(error)
      }
    }else{
      setCostoEnvío(0);
    }
  };

  const clickFlechaAtras = () => {
    // console.log('entré en clickFlechaAtras')
    navigate('/')
  }

  const selectTipoComprobante = (e) => {
    if (e.target.value === '2') {
      setIsFacturaSelected(true)
      setTipoComprobante(e.target.options[e.target.selectedIndex].text);
      // setDocComprobante(valueRUC);
    } else {
      setIsFacturaSelected(false)
      setTipoComprobante(e.target.options[e.target.selectedIndex].text);
      // localStorage.getItem('loggedUser') ? setDocComprobante(JSON.parse(localStorage.getItem('loggedUser')).document) : setDocComprobante('')
    }
  }

  const clickValidarRUC = async(e) =>{
    e.preventDefault();
    const idInputIngreseRUC = document.getElementById("idInputIngreseRUC").value;
    // console.log(idInputIngreseRUC)
    let url = 'https://api.mundosantanatura.com/api/StoreEvents/ValidarRUC'
    try {
      // const token='feLO0xIWlBK97vMqsdWdCBVBVwbtFAv8Q7Nn7TOzD1i2lyi3pO8QTf0jGwOm';
      const respuesta = await axios({
        headers: {
          // 'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        method: 'post',
        url: url,
        data: {
          document: idInputIngreseRUC
          // force: "true"
        },
      })
      // console.log(respuesta.data.success)
      const respuestaApi = respuesta.data.data.address;
      if (respuestaApi === null || respuestaApi === "" || respuestaApi === "-") {
        document.getElementById('modal').style.display = 'flex';
        setTimeout(() => {
          document.getElementById('modal').style.opacity = '1';
        }, "100")
        document.querySelector('body').style.overflowY= 'hidden';
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: `Ingrese un RUC válido.`,
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }

    // document.getElementById('modal').style.display = 'flex';
    // setTimeout(() => {
    //   document.getElementById('modal').style.opacity = '1';
    // }, "100")
    // document.querySelector('body').style.overflowY= 'hidden';
  }

  function validacion() {
    if(!localStorage.getItem('loggedUser')){
      Swal.fire({
        icon: 'error',
        title: 'Oops...!',
        text: 'Debe loguearse para realizar la compra.',
        confirmButtonText: 'Ir a Login'
      }).then(() => {
        navigate('/Login')
      })
      return false;
    }
    if(JSON.parse(localStorage.getItem('carritoLength')) === 0 ){
      // swalError('Debe ingresar productos al carrito.');
      Swal.fire({
        icon: 'error',
        title: 'Oops...!',
        text: 'Debe ingresar productos al carrito.',
        confirmButtonText: 'Ir a Tienda'
      }).then(() => {
        navigate('/');
        window.scroll(0, 0);
      })
      return false;
    }
    if(!(document.getElementById('bloqueMovilUbi').classList.contains('selected')) && !(document.getElementById('bloqueTiendaUbi').classList.contains('selected'))){
      swalError('Debe seleccionar un tipo de entrega.');
      return false;
    }
    if(!document.getElementById('idInputLocalSanIsidro')){
      if(document.getElementById('valueSelectDepartamento').value === '000'){
        swalError('Debe seleccionar un departemento.');
        return false;
      }
      if(document.getElementById('valueSelectProvincia').value === '000' || document.getElementById('valueSelectProvincia').value === ''){
        swalError('Debe seleccionar una provincia.');
        return false;
      }
      if(document.getElementById('valueSelectDistrito').value === '000' || document.getElementById('valueSelectDistrito').value === ''){
        swalError('Debe seleccionar un distrito.');
        return false;
      }
      if(document.getElementById('idInputDireccion').value === ''){
        swalError('Debe ingresar su dirección.');
        return false;
      }
    }
    if(document.getElementById('idSelectTipoComprobante').value === '0'){
      swalError('Debe seleccionar un tipo de comprobante.');
      return false;
    }
    if(document.getElementById('idSelectTipoComprobante').value === '2' && Object.values(objetoComprobante).length === 0){
      swalError('Debe ingresar y validar el número de RUC.');
      return false;
    }
    return true;
  }

  function swalError(msg){
    Swal.fire(
      {
        icon: 'error',
        title: 'Oops...!',
        text: msg
      }
    )
  }

  const CompletePurchase = async() =>{
    let valid = validacion();
    if(valid){
      // // console.log('hola')
      // if(document.getElementById('idSelectTipoComprobante').value="2"){
      //   document.getElementById('idInputIngreseRUC').value= '';
      // }
      let docComprobante = '';
      let direccionFactu = '';      
      if(tipoComprobante === 'Boleta'){   
        docComprobante = JSON.parse(localStorage.getItem('loggedUser')).document;
        // direccionFactu = JSON.parse(localStorage.getItem('loggedUser')).address;
        // console.log(`addressPersonal: ${addressPersonal}`)
        direccionFactu=first;
      }
      if(tipoComprobante === 'Factura'){
        docComprobante = objetoComprobante.numeroRuc;
        console.log(`addressPersonal: ${objetoComprobante.direccionFiscal}`)
        direccionFactu = objetoComprobante.direccionFiscal
      }

      let lstCart = []
      JSON.parse(localStorage.getItem('carrito')).forEach(el => {
        let newObject = {};
        newObject = {
          "idProductoCountry": el.idProducto,
          "nombre": el.descProducto,
          "cantidad": el.cantidad,
          "subTotal": el.subTotal,
          "price": el.precio,
        }
        lstCart.push(newObject);
      });
      // console.log(lstCart)
      let valorUbigeo = ''
      if(!document.getElementById('idBloqueLocalSeleccionado')){//Si el elemento idBloqueLocalSeleccionado no existe (seleccioné tipo entrega Delivery)
        console.log('el elemento idBloqueLocalSeleccionado no existe')
        variableGlobal.subTotal=costoEnvío.toFixed(2);
        variableGlobal.price=costoEnvío.toFixed(2);
        lstCart.push(variableGlobal);

        valorUbigeo = document.getElementById('valueSelectDepartamento').value + 
        document.getElementById('valueSelectProvincia').value +
        document.getElementById('valueSelectDistrito').value;
      } else{        
        valorUbigeo = '015012801311';
      }

      console.log(`direccion_Delivery: ${direccion}`)
      console.log(typeof direccion)
      console.log(`direccion_Facturacion: ${direccionFactu}`)
      console.log(typeof direccionFactu)
      console.log(`ubigeo: ${valorUbigeo}`)
      console.log(typeof valorUbigeo)
      console.log(`tipo_Comprobante: ${tipoComprobante}`)
      console.log(typeof tipoComprobante)
      console.log(`doc_Comprobante: ${docComprobante}`)
      console.log(typeof docComprobante)
      console.log(lstCart)
      console.log(typeof lstCart)
      console.log(`idCliente: ${JSON.parse(localStorage.getItem('loggedUser')).idClient}`)
      console.log(typeof JSON.parse(localStorage.getItem('loggedUser')).idClient)
      let url = 'https://api.mundosantanatura.com/api/StoreEvents/CompletePurchase';
      var dataSend = {
        direccion_Delivery: direccion,
        direccion_Facturacion: direccionFactu,
        ubigeo: valorUbigeo,
        tipo_Comprobante: tipoComprobante,
        doc_Comprobante: docComprobante,
        lstCart: lstCart,
        idCliente: JSON.parse(localStorage.getItem('loggedUser')).idClient,
      };
      console.log(dataSend);
      try {
        const respuesta = await axios({
          method: 'post',
          url: url,
          data: {
            direccion_Delivery: direccion,
            direccion_Facturacion: direccionFactu,
            ubigeo: valorUbigeo,
            tipo_Comprobante: tipoComprobante,
            doc_Comprobante: docComprobante,
            lstCart: lstCart,
            idCliente: JSON.parse(localStorage.getItem('loggedUser')).idClient,
          },
        })
        console.log(respuesta)
        clearCart();
        navigate('/CompraExitosa')
        
        limpiarObjetoComprobante({});
        // if (respuesta.data.result === 1) {
        // } else {
        // }
      } catch (error) {
        console.log(error)
      }
    }
  }
  return (
    <div className="bloqueTuCarrito">
      <section className="bloqueTuCarrito__top">
        <img
          src={btnAtrasCarrito}
          alt="btnAtrasCarrito"
          onClick={clickFlechaAtras}
        />
        <h1>TU CARRITO</h1>
        <button onClick={clearCart}>VACIAR</button>
      </section>

      <section className="bloqueTuCarrito__listaProductosAñadidos">
        <div className="bloqueTextoCantidadProductos">
          {
            (cart.length === 1) 
            ? <p>Tienes {cart.length} producto en tu carrito</p>
            : <p>Tienes {cart.length} productos en tu carrito</p>
          }
        </div>
        <div className="box">
          {Array.isArray(cart)
            ? cart.map((item, index) => (
                <CartItem key={index} data={item} delFromCart={delFromCart} masUno={addOneMoreFromProductAtCart} menosUno={removeOneMinusFromProductAtCart}/>
              ))
            : null}
        </div>

        <UbicacionPedido direccion_Delivery={direccion_Delivery} getCostoDeEnvío={getCostoDeEnvío} />

        <div className="bloqueTipoDeComprobante">
          <div className='bloqueSelectTipoComprobantes'>
            <select
              id="idSelectTipoComprobante"
              name="select"
              onChange={(e) => selectTipoComprobante(e)}
            >
              <option value="0">Seleccione tipo de Comprobante</option>
              <option value="1">Boleta</option>
              <option value="2">Factura</option>
            </select>
          </div>
          {!!isFacturaSelected ? (
            <div className="bloqueIngreseRuc">
              <form onSubmit={clickValidarRUC}>
                <input id="idInputIngreseRUC" required autoComplete='off' placeholder="Ingrese Número de RUC"></input>
                <input type='submit' id='mostrar-modal' value="Validar RUC"/>
              </form>
            </div>
          ) : null}
        </div>
        <div className="bloqueComentarios">
          <p>
            Nos encantaría recibir sus comentarios acerca de la compra. Si
            tienes un comentario extra puedes dejarlo a continuación (máx. 180
            carácteres).
          </p>
          <textarea onChange={()=>{console.log(objetoComprobante, Object.values(objetoComprobante).length)}} placeholder='Ingrese su comentario' maxLength={180}></textarea>
        </div>
        <div className="bloqueTotalConfirmar">
          <div className="bloqueCostoDeEnvío">
            <label>Costo de envío:</label>
            <span>S/ {!(document.getElementById('idInputLocalSanIsidro') && costoEnvío !== 0) ? costoEnvío.toFixed(2) : 2}</span>
          </div>
          <div className="bloqueSubTotal">
            <label>Sub-Total:</label>
            <span>S/ {cart.reduce((subTotal, produc) => subTotal += produc.subTotal, 0)}.00</span>
          </div>
          <div className="bloqueTotal">
            <label>TOTAL:</label>
            <span>S/ {(cart.reduce((subTotal, produc) => subTotal += produc.subTotal, 0) + Number.parseFloat(costoEnvío)).toFixed(2)}</span>
          </div>
          <input type='submit' className="btnConfirmar" value='CONFIRMAR' onClick={CompletePurchase}/>
        </div>
        <span className='mensajeLegal'>* Los precios de nuestros productos en la página ya incluyen IGV.</span>
      </section>
    </div>
  )
}

export default TuCarrito
